<script>
</script>

<template>
  <main>
    <section class="contact_tittle">
      <h1>{{ $t("contactTitle") }}</h1>
    </section>
    <section class="contact">
      <div class="contact_preface">
        <p>
          {{ $t("sentence1") }}<br>
          {{ $t("sentence2") }}
        </p>
        <p class="caution">
          {{ $t("caution") }}
        </p>
      </div>
      <div class="contact_btn_container">
        <div class="contact_btn">
          <a href="mailto:info@yuni-cosme.com?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%E3%82%92%E3%81%94%E8%A8%98%E5%85%A5%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84">
            {{ $t("contactButton") }}
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
h1{
  font-size: 24px;
  font-weight: bold;
}
h2{
  font-size: 22px;
  font-weight: bold;
}
p, li{
  font-size: 14px;
}
ul{
  list-style-position: inside;
}
a{
  text-decoration: none;
  color: #777;
}
.contact_tittle{
  padding: 0 40px;
  background-color: #fff;
  text-align: center;
}
.contact_tittle h1{
  padding: 40px;
}
.contact{
  padding: 0 40px;
  background-color:#F5EAE7;
}
.contact_preface{
  padding: 60px 0;
}
.contact_preface p:last-child{
  padding-top: 10px;
}
.caution{
  color: #F29F8F;
}
.contact_btn_container{
  text-align: center;
}
.contact_btn{
  padding-bottom: 40px;
}
.contact_btn a{
  padding: 16px 60px;
  display: block;
  position: relative;
  background-color: #FFF;
  border-radius: 100vh;
  border: #777 solid 1px;
}
.contact_btn p{
  padding-top: 20px;
}
</style>
